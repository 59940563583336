@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

.App {
  text-align: center;
  background-color: rgb(231, 231, 231);
  font-family: 'Roboto Condensed', sans-serif;
  user-select: none;
}

/* ----- Header ----- */
.header-text {
  font-size: 30px;
  font-weight: 600;
  color: rgb(241, 241, 241);
}

/* ----- Info Card & Charts Title ----- */
.map-change,
.barchart-change,
.data-change {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.mapchart-title {
  width: 250px;
  font-size: 18px;
  font-weight: bold;
}

.arrow {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: rgb(49, 143, 250);
  transition: 0.3s;
}

.arrow:hover {
  color: rgb(255, 208, 0);
}

.arrow:focus {
  background-color: transparent;
}

.material-icons {
  font-size: 30px;
}

/* ----- Info Cards ----- */
.last-updated {
  margin: auto;
  padding: 2px;
  border: solid 1px rgb(201, 201, 201);
  border-radius: 10px;
  background-color: rgb(251, 251, 251);
}

.info-cards {
  margin: 0 auto 25px;
  border: solid 1px rgb(201, 201, 201);
  border-radius: 10px;
  background-color: rgb(251, 251, 251);
}

.info-card-summary {
  width: 250px;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  background-color: rgb(251, 251, 251);
  font-weight: 600;
  margin-bottom: 10px;
}

.info-cards-scroll {
  margin: auto;
  height: 75vh;
  overflow-y: auto;
  overflow-x: auto;
  border-top: solid 0.5px rgb(201, 201, 201);
  background-color: rgb(251, 251, 251);
}

.info-card {
  font-size: 18px;
  text-align: center;
  padding: 2px;
  border-bottom: solid 0.5px rgb(201, 201, 201);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(251, 251, 251);
}

.info-card-figure {
  padding: 3px;
  width: 100px;
  text-align: center;
  font-weight: 600;
}

.info-card-flag {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-card-flag-img {
  height: 20px;
  width: auto;
  border: solid 0.5px;
  border-color: grey;
}

.info-card-country {
  width: 100px;
}

/* ----- Map ----- */
.map-svg {
  margin: auto;
  background-color: rgb(247, 247, 247);
  border-radius: 10px;
}

.tooltip-text1 {
  text-decoration: underline;
  font-size: 19px;
  font-weight: bold;
}
.tooltip-text2,
.tooltip-text3,
.tooltip-text4,
.tooltip-text5 {
  font-weight: bold;
}

.legend-tick,
.legend-text {
  font-size: 12px;
}

/* ----- Charts ----- */
.line-chart,
.bar-chart {
  margin-bottom: 20px;
}

.linechart-svg,
.barchart-svg {
  margin: auto;
  background-color: rgb(251, 251, 251);
  border-radius: 10px;
}

.x-axis,
.y-axis,
.y-axis2 {
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
}

.tooltip-date,
.tooltip-case,
.tooltip-death {
  font-size: 18px;
  font-weight: bold;
}

.axis-label {
  font-weight: bold;
}

.line-title,
.bar-title {
  font-size: 25px;
  font-weight: bold;
  text-decoration: underline;
}
.ask-hover {
  font-size: 25px;
  font-weight: bold;
}

.d3-tip {
  padding: 3px 5px;
  border-radius: 5px;
  font-family: 'Roboto Condensed', sans-serif;
}

.tip-date,
.tip-figure {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.grid {
  stroke: lightgray;
  stroke-opacity: 0.15;
  shape-rendering: crispEdges;
}

/* ----- Footer ----- */
.footer {
  padding: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* ----- React Transition Group 1 ----- */
.transition--enter {
  opacity: 0;
}
.transition--enter-active {
  opacity: 1;
  transition: opacity 0.4s;
}
.transition--enter-done {
  opacity: 1;
}

.transition--exit {
  opacity: 1;
}
.transition--exit-active {
  opacity: 0;
  transition: opacity 0.4s;
}
.transition--exit-done {
  opacity: 0;
}

/* ----- React Transition Group 2 ----- */
.transition2--enter {
  transform: translateX(-20px);
}
.transition2--enter-active {
  transform: translateX(0);
  transition: transform 0.8s cubic-bezier(0.16, 1, 0.3, 1);
}
.transition2--enter-done {
  transform: translateX(0);
}

.transition2--exit {
  transform: translateX(0);
}
.transition2--exit-active {
  transform: translateX(-20px);
  transition: transform 0.8s cubic-bezier(0.83, 0, 0.17, 1);
}
.transition2--exit-done {
  transform: translateX(-20px);
}
